import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SageOrderSyncDialogData {
  payload: any;
}

export interface SageOrderSyncDialogResult {}

@Component({
  selector: 'x-sage-order-sync-payload-dialog',
  templateUrl: './sage-order-sync-payload-dialog.component.html',
  styleUrls: ['./sage-order-sync-payload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SageOrderSyncPayloadDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: SageOrderSyncDialogData,
    private dialogRef: MatDialogRef<SageOrderSyncPayloadDialogComponent, SageOrderSyncDialogResult>,
  ) {}
}
