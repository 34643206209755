import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import {
  SageOrderSyncDialogData,
  SageOrderSyncDialogResult,
  SageOrderSyncPayloadDialogComponent,
} from '../components/sage-order-sync-payload-dialog/sage-order-sync-payload-dialog.component';

@Injectable()
export class SageOrderSyncDialogService {
  constructor(private dialogs: MatDialog) {}

  openSageOrderSyncPayloadDialog(payload: any) {
    return this.dialogs.open<
      SageOrderSyncPayloadDialogComponent,
      SageOrderSyncDialogData,
      SageOrderSyncDialogResult
    >(SageOrderSyncPayloadDialogComponent, {
      data: { payload },
    });
  }
}
